<template>
	<div>
		Help Component
	</div>
</template>

<script>
	export default{
		
	}
</script>

<style scoped>

</style>

